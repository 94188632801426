import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
const firebaseConfig = {
    apiKey: 'FIREBASE_API_KEY',
    authDomain: 'tapedrop-6e210.firebaseapp.com',
    projectId: 'tapedrop-6e210',
    storageBucket: 'tapedrop-6e210.appspot.com',
    messagingSenderId: '449069459533',
    appId: '1:449069459533:web:f75ca4d5d539b652fdd404'
};
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = whichAuth();
export const db = getFirestore(app);
export function awaitAuth(auth) {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
}
function whichAuth() {
    let auth;
    if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(app, {
            persistence: indexedDBLocalPersistence
        });
    }
    else {
        auth = getAuth();
    }
    return auth;
}

<script lang="ts">
  import Icon from 'svelte-awesome'
  import { promise } from '../stores'
  import { activePage } from '../helpers'
  import { sliders } from 'svelte-awesome/icons'
  import { getNearbyArtists, getLocationPermissions } from '../api/nearby'

  import Filters from '../components/Filters.svelte'
  import ArtistCard from '../components/ArtistCard.svelte'

  let permissionsPromise = getLocationPermissions()
  let showFilters = false
  let loadingText = 'Finding Artists...'

  activePage('discover')
  promise.set(getNearbyArtists(10, 'all'))

  function filterToggle() {
    showFilters = !showFilters
    if (showFilters) {
      document.getElementById('filters').classList.remove('d-none')
    } else {
      document.getElementById('filters').classList.add('d-none')
    }
  }
</script>

{#await permissionsPromise then permissions}
  {#if !permissions}
    <p class="text-center">
      You haven't shared your location, so here's Philly
    </p>
  {/if}
{/await}
<h1 class="page-title text-center">Nearby Music</h1>
<div class="d-flex justify-content-center m-10">
  <!-- svelte-ignore a11y-invalid-attribute -->
  <a class="glowing" href="#" on:click={filterToggle}>
    <Icon class="sliders" data={sliders} />
  </a>
</div>
<div id="filters" class="d-none">
  <Filters />
</div>
{#await $promise}
  <p class="loading">{loadingText}</p>
{:then artists}
  {#if artists && artists.length == 0}
    <p class="text-center">No Artists Found Nearby</p>
    {#if !showFilters}
      <p class="text-center">Increase distance by tapping the icon above</p>
    {/if}
  {:else if artists}
    {#each artists as artist}
      <ArtistCard {artist} />
    {/each}
  {/if}
{/await}

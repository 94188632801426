import { writable } from 'svelte/store';
import { nullAccount } from './helpers';
import { getNearbyArtists } from './api/nearby';
export const account = writable(nullAccount);
export const alert = writable('');
export const authenticated = writable(null);
export const distance = writable(10);
export const genre = writable('all');
export const page = writable('discover');
export const promise = writable(getNearbyArtists());
export const uploading = writable(false);

<script lang="ts">
  import { distance, promise, genre } from '../stores'
  import { activePage, activeFilters } from '../helpers'
  import { getNearbyArtists, getNearbyGenres } from '../api/nearby'

  distance.set(10)
  let genrePromise = $promise
  $: genresPromise = getNearbyGenres($distance, genrePromise)

  activePage('discover')

  async function onClick(e, miles: number, selectedGenre) {
    distance.set(miles)
    genre.set(selectedGenre)
    promise.set(getNearbyArtists($distance, $genre))
    if (e.target.classList.contains('distance')) {
      genrePromise = getNearbyArtists($distance, 'all')
    }
    activeFilters(e)
  }
</script>

<div class="d-flex justify-content-center">
  <button
    on:click={(e) => onClick(e, 10, 'all')}
    class="badge m-5 distance activeDistance"
    >10 miles
  </button>

  <button on:click={(e) => onClick(e, 50, 'all')} class="badge m-5 distance"
    >50 miles
  </button>

  <button
    href="#"
    on:click={(e) => onClick(e, 100, 'all')}
    class="badge m-5 distance"
    >100 miles
  </button>

  <button
    href="#"
    on:click={(e) => onClick(e, 25000, 'all')}
    class="badge m-5 distance"
    >Everywhere
  </button>
</div>
{#await genresPromise then genres}
  <div class="d-flex justify-content-center flex-wrap">
    <button
      on:click={(e) => onClick(e, $distance, 'all')}
      class="badge m-5 genre activeGenre"
      >all genres
    </button>
    {#each genres as genre}
      <button
        on:click={(e) => onClick(e, $distance, genre)}
        class="badge m-5 genre"
        >{genre}
      </button>
    {/each}
  </div>
{/await}

import { get } from 'svelte/store';
import { account, page } from '../stores';
import { Capacitor } from '@capacitor/core';
import { docToJSON, hasLinks } from '../helpers';
import { auth, awaitAuth, db } from '../firebase';
import { Geolocation } from '@capacitor/geolocation';
import { distanceBetween, geohashForLocation, geohashQueryBounds } from 'geofire-common';
import { collection, doc, endAt, getDoc, getDocs, limit, orderBy, query, setDoc, startAt, where } from 'firebase/firestore';
export async function getNearbyArtists(miles = 10, genre = 'all') {
    await awaitAuth(auth);
    const { latitude, longitude } = await geolocation();
    const center = [latitude, longitude];
    const radiusInMeters = miles * 1609.34;
    const bounds = geohashQueryBounds(center, radiusInMeters);
    const accountsRef = collection(db, 'accounts');
    const promises = [];
    for (const b of bounds) {
        let q;
        if (genre == 'all') {
            q = query(accountsRef, orderBy('geohash', 'desc'), startAt(b[1]), endAt(b[0]), limit(25));
        }
        else {
            q = query(accountsRef, where('genres', 'array-contains', genre), orderBy('geohash', 'desc'), startAt(b[1]), endAt(b[0]), limit(25));
        }
        promises.push(getDocs(q));
    }
    const nearbyArtists = Promise.all(promises)
        .then((snapshots) => {
        const results = [];
        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                const lat = doc.get('latitude');
                const lng = doc.get('longitude');
                const distanceInMeters = distanceBetween([lat, lng], center) * 1000;
                if (distanceInMeters <= radiusInMeters) {
                    results.push(doc);
                }
            }
        }
        return results;
    })
        .then((results) => {
        const nearbyArtists = [];
        for (const doc of results) {
            let artist = docToJSON(doc.data());
            if ((artist.uid !== get(account).uid ||
                (miles == 25000 && genre != 'all')) &&
                hasLinks(artist)) {
                nearbyArtists.push(artist);
            }
        }
        return nearbyArtists;
    });
    return nearbyArtists;
}
export async function getNearbyGenres(distance, promise) {
    if (distance == 25000) {
        return await getGenres();
    }
    else {
        let genres = [];
        let nearbyArtists = await promise;
        for (const artist of nearbyArtists) {
            for (const genre of artist.genres) {
                if (genre != '') {
                    genres.push(genre);
                }
            }
        }
        let uniqueGenres = [...new Set(genres)];
        return uniqueGenres.sort();
    }
}
export async function getGenres() {
    const genresRef = doc(db, 'genres', 'genres');
    const genres = await getDoc(genresRef);
    return genres.data().genres.sort();
}
export async function getLocationPermissions() {
    if (!Capacitor.isNativePlatform())
        return true;
    const permissions = await Geolocation.requestPermissions();
    return permissions.location != 'denied';
}
async function geolocation() {
    let coords = {
        latitude: 39.95299736578519,
        longitude: -75.16350476023143
    };
    if (get(page) != 'artist') {
        const permissions = await getLocationPermissions();
        if (permissions) {
            const geolocation = await Geolocation.getCurrentPosition();
            coords = geolocation.coords;
        }
        const { latitude, longitude } = coords;
        const geohash = geohashForLocation([latitude, longitude]);
        if (get(account).uid) {
            const accountRef = doc(db, 'accounts', get(account).uid);
            setDoc(accountRef, { latitude: latitude, longitude: longitude, geohash: geohash }, { merge: true });
        }
    }
    return coords;
}

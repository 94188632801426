<script lang="ts">
  import { activePage } from '../helpers'
  import { getLibrary } from '../api/artists'

  import ArtistCard from '../components/ArtistCard.svelte'

  let promise = getLibrary()

  activePage('library')

  function scrollToTop(node) {
    node.scrollIntoView()
  }
</script>

<h1 use:scrollToTop class="page-title text-center">Library</h1>
{#await promise}
  <p class="loading">Loading Library...</p>
{:then artists}
  {#if artists.length == 0}
    <p class="text-center">No Artists in Library</p>
  {:else}
    {#each artists as artist}
      <ArtistCard {artist} />
    {/each}
  {/if}
{/await}

import { get } from 'svelte/store';
import { db, storage } from '../firebase';
import { getAccount } from '../api/account';
import { account, uploading } from '../stores';
import { doc, setDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
export async function getProfilePicture(uid, imageURL) {
    const storageRef = ref(storage, imageURL);
    const url = await getDownloadURL(storageRef);
    if (url) {
        const img = document.getElementById(uid + '-picture');
        if (img) {
            //@ts-ignore
            img.src = url;
            img.onload = () => {
                img.classList.remove('d-none');
            };
        }
    }
}
export async function uploadProfilePicture(uid, file) {
    uploading.set(true);
    if (get(account).imageURL) {
        const deleteRef = ref(storage, get(account).imageURL);
        try {
            await deleteObject(deleteRef);
        }
        catch (e) {
            console.error(e);
        }
    }
    const imageURL = uid + '/profilePicture/' + file.name;
    const storageRef = ref(storage, imageURL);
    await uploadBytes(storageRef, file);
    const docRef = doc(db, 'accounts', uid);
    await setDoc(docRef, { imageURL: uid + '/profilePicture/' + file.name }, { merge: true });
    const url = await getDownloadURL(storageRef);
    await getAccount(uid);
    if (url) {
        const img = document.getElementById(uid + '-picture');
        //@ts-ignore
        img.src = url;
        img.onload = () => {
            img.classList.remove('d-none');
        };
    }
    uploading.set(false);
}

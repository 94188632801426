import halfmoon from 'halfmoon';
import { alert } from './stores';
import { Browser } from '@capacitor/browser';
import { Timestamp } from '@firebase/firestore';
import { AppLauncher } from '@capacitor/app-launcher';
export function activeFilters(e) {
    if (e.target.classList.contains('distance')) {
        let active = document.getElementsByClassName('activeDistance')[0];
        active && active.classList.remove('activeDistance');
        e.target.classList.add('activeDistance');
    }
    if (e.target.classList.contains('genre')) {
        let active = document.getElementsByClassName('activeGenre')[0];
        active && active.classList.remove('activeGenre');
        e.target.classList.add('activeGenre');
    }
}
export async function activePage(pageName) {
    let selected = document.getElementsByClassName(pageName)[0];
    let lastselected = document.getElementsByClassName('selectedPage')[0];
    lastselected && lastselected.classList.remove('selectedPage');
    selected && selected.classList.add('selectedPage');
}
export function alertTimeout() {
    setTimeout(() => {
        alert.set('');
    }, 3000);
}
export function autocomplete(inp, arr) {
    function addActive(x) {
        if (!x)
            return false;
        removeActive(x);
        if (currentFocus >= x.length)
            currentFocus = 0;
        if (currentFocus < 0)
            currentFocus = x.length - 1;
        x[currentFocus].classList.add('autocomplete-active');
    }
    function removeActive(x) {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }
    function closeAllLists(elmnt = null) {
        var x = document.getElementsByClassName('autocomplete-items');
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }
    var currentFocus;
    inp.addEventListener('input', function (e) {
        var a, b, i, val = this.value;
        closeAllLists();
        if (!val) {
            return;
        }
        currentFocus = -1;
        a = document.createElement('DIV');
        a.setAttribute('id', this.id + 'autocomplete-list');
        a.setAttribute('class', 'autocomplete-items');
        this.parentNode.appendChild(a);
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                b = document.createElement('DIV');
                /*make the matching letters bold:*/
                b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + '</strong>';
                b.innerHTML += arr[i].substr(val.length);
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                b.addEventListener('click', function (e) {
                    inp.value = this.getElementsByTagName('input')[0].value;
                    closeAllLists();
                });
                a.appendChild(b);
            }
        }
    });
    inp.addEventListener('keydown', function (e) {
        var x = document.getElementById(this.id + 'autocomplete-list');
        if (x)
            var a = x.getElementsByTagName('div');
        if (e.keyCode == 40) {
            currentFocus++;
            addActive(a);
        }
        else if (e.keyCode == 38) {
            currentFocus--;
            addActive(a);
        }
        else if (e.keyCode == 13) {
            e.preventDefault();
            if (currentFocus > -1) {
                if (a)
                    a[currentFocus].click();
            }
        }
    });
    document.addEventListener('click', function (e) {
        closeAllLists(e.target);
    });
}
export function docToJSON(doc) {
    var _a, _b, _c, _d, _e, _f;
    return {
        uid: doc === null || doc === void 0 ? void 0 : doc.uid,
        name: doc === null || doc === void 0 ? void 0 : doc.name,
        genres: doc === null || doc === void 0 ? void 0 : doc.genres,
        location: doc === null || doc === void 0 ? void 0 : doc.location,
        links: {
            spotify: (_a = doc === null || doc === void 0 ? void 0 : doc.links) === null || _a === void 0 ? void 0 : _a.spotify,
            apple: (_b = doc === null || doc === void 0 ? void 0 : doc.links) === null || _b === void 0 ? void 0 : _b.apple,
            bandcamp: (_c = doc === null || doc === void 0 ? void 0 : doc.links) === null || _c === void 0 ? void 0 : _c.bandcamp,
            soundcloud: (_d = doc === null || doc === void 0 ? void 0 : doc.links) === null || _d === void 0 ? void 0 : _d.soundcloud,
            instagram: (_e = doc === null || doc === void 0 ? void 0 : doc.links) === null || _e === void 0 ? void 0 : _e.instagram,
            youtube: (_f = doc === null || doc === void 0 ? void 0 : doc.links) === null || _f === void 0 ? void 0 : _f.youtube
        },
        library: doc === null || doc === void 0 ? void 0 : doc.library,
        latitude: doc === null || doc === void 0 ? void 0 : doc.latitude,
        longitude: doc === null || doc === void 0 ? void 0 : doc.longitude,
        imageURL: doc === null || doc === void 0 ? void 0 : doc.imageURL
    };
}
export function formMap(data) {
    return {
        name: data.name.trim(),
        genres: [
            data.firstGenre.toLowerCase().trim(),
            data.secondGenre.toLowerCase().trim()
        ],
        location: data.location.toUpperCase().trim(),
        links: {
            spotify: prependHttps(data.spotify.trim()),
            apple: prependHttps(data.apple.trim()),
            bandcamp: prependHttps(data.bandcamp.trim()),
            soundcloud: prependHttps(data.soundcloud.trim()),
            instagram: formatInstagram(data.instagram.trim()),
            youtube: prependHttps(data.youtube.trim())
        },
        updated_at: Timestamp.now()
    };
}
export function halfmoonInit() {
    halfmoon.onDOMContentLoaded();
    if (window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches) {
        halfmoon.toggleDarkMode();
    }
}
export function hasLinks(artist) {
    var _a, _b, _c, _d;
    return (((_a = artist === null || artist === void 0 ? void 0 : artist.links) === null || _a === void 0 ? void 0 : _a.spotify) ||
        ((_b = artist === null || artist === void 0 ? void 0 : artist.links) === null || _b === void 0 ? void 0 : _b.apple) ||
        ((_c = artist === null || artist === void 0 ? void 0 : artist.links) === null || _c === void 0 ? void 0 : _c.bandcamp) ||
        ((_d = artist === null || artist === void 0 ? void 0 : artist.links) === null || _d === void 0 ? void 0 : _d.soundcloud));
}
export function inArray(x, array) {
    if (array.indexOf(x) !== -1) {
        return x;
    }
}
export async function openSite(url) {
    await Browser.open({ url: url });
}
export async function openApp(url) {
    await AppLauncher.openUrl({ url: url });
}
export const params = new URLSearchParams(window.location.search);
export const nullAccount = {
    uid: '',
    name: '',
    genres: ['', ''],
    location: '',
    links: {
        spotify: '',
        apple: '',
        bandcamp: '',
        soundcloud: '',
        instagram: '',
        youtube: ''
    },
    library: [],
    imageURL: ''
};
export function validate(data) {
    let errors = {
        name: null,
        genres: null,
        spotify: null,
        apple: null,
        bandcamp: null,
        soundcloud: null,
        instagram: null,
        youtube: null
    };
    if (data.name.trim().length < 1) {
        errors['name'] = 'name required';
    }
    if (data.name.trim().length > 40) {
        errors['name'] = 'name too long';
    }
    if (data.firstGenre.trim().length > 20) {
        errors['firstGenre'] = 'genre too long';
    }
    if (data.firstGenre.trim() != '' &&
        !/^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(data.firstGenre.trim())) {
        errors['firstGenre'] = 'letters and spaces only';
    }
    if (data.secondGenre.trim().length > 20) {
        errors['secondGenre'] = 'genre too long';
    }
    if (data.secondGenre.trim() != '' &&
        !/^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(data.secondGenre.trim())) {
        errors['secondGenre'] = 'letters and spaces only';
    }
    if (data.firstGenre.trim() == '' && data.secondGenre.trim() != '') {
        errors['firstGenre'] = 'first genre required';
    }
    if (data.secondGenre.trim() == data.firstGenre.trim() &&
        data.secondGenre.trim() != '') {
        errors['secondGenre'] = 'genres must be unique';
    }
    if (domainTest(data.spotify, 'spotify.com')) {
        errors['spotify'] = 'spotify link invalid';
    }
    if (domainTest(data.apple, 'apple.com')) {
        errors['apple'] = 'apple link invalid';
    }
    if (domainTest(data.bandcamp, 'bandcamp.com')) {
        errors['bandcamp'] = 'bandcamp link invalid';
    }
    if (domainTest(data.soundcloud, 'soundcloud.com')) {
        errors['soundcloud'] = 'soundcloud link invalid';
    }
    if (domainTest(data.youtube, 'youtube.com') &&
        domainTest(data.youtube, 'youtu.be')) {
        errors['youtube'] = 'youtube link invalid';
    }
    if (!/^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/.test(data.instagram.trim()) &&
        data.instagram.trim() != '') {
        errors['instagram'] = 'instagram username invalid';
    }
    if (!Object.values(errors).some(function (x) {
        return x !== null;
    })) {
        return null;
    }
    return errors;
}
function domainTest(url, compareTo) {
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (url.length == 0) {
        return false;
    }
    if (url.length < 100 && urlRegex.test(url)) {
        let domain = new URL(prependHttps(url.trim()));
        let urlParts = domain.hostname.split('.').reverse();
        let hostname = urlParts[1] + '.' + urlParts[0];
        return hostname != compareTo;
    }
    else {
        return true;
    }
}
function formatInstagram(username) {
    if (username != '') {
        return 'https://www.instagram.com/' + username;
    }
    return '';
}
function prependHttps(url) {
    if (url.length > 0 && url.indexOf('https://') !== 0) {
        return 'https://' + url;
    }
    else {
        return url;
    }
}

<script lang="ts">
  import { page } from '../stores'
  import Icon from 'svelte-awesome'
  import { params } from '../helpers'
  import { apple, google } from 'svelte-awesome/icons'
  import { appleLogin, googleLogin } from '../api/auth'
</script>

<h1 class="page-title text-center">Sign In</h1>
<p class="text-center">
  Create an account to share your music and build your library
</p>
<!-- svelte-ignore a11y-invalid-attribute -->
<a href="#" on:click={googleLogin} class="card social-login border">
  <Icon data={google} scale={2} />
  <p>Sign in with Google</p>
</a>
<!-- svelte-ignore a11y-invalid-attribute -->
<a href="#" on:click={appleLogin} class="card social-login border">
  <Icon data={apple} scale={2} />
  <p>Sign in with Apple</p>
</a>
{#if params.has('artist')}
  <!-- svelte-ignore a11y-invalid-attribute -->
  <a href="#" on:click={() => page.set('artist')}>
    <p class="text-center text-muted">Return to Artist Card</p>
  </a>
{:else}
  <!-- svelte-ignore a11y-invalid-attribute -->
  <a href="#" on:click={() => page.set('discover')}>
    <p class="text-center text-muted">Continue without an account</p>
  </a>
{/if}

<script lang="ts">
  import { onMount } from 'svelte'
  import { account } from '../stores'
  import { getGenres } from '../api/nearby'
  import { autocomplete, openSite, validate } from '../helpers'
  import { deleteAccount, updateAccount } from '../api/account'

  let instagram = instagramUsername()
  let errors = null
  $: errors = errors

  function instagramUsername() {
    if ($account.links.instagram != null) {
      return $account.links.instagram.split('/').pop()
    }
    return ''
  }

  function onSubmit(e) {
    const formData = new FormData(e.target)
    const data: any = {}
    for (let field of formData) {
      const [key, value] = field
      data[key] = value
    }
    errors = validate(data)
    if (!errors) {
      updateAccount($account.uid, data)
    } else {
      scrollToTop(document.getElementById('top'))
    }
  }

  function scrollToTop(node) {
    node.scrollIntoView()
  }

  onMount(async () => {
    const genres = await getGenres()
    autocomplete(document.getElementById('genreInput1'), genres)
    autocomplete(document.getElementById('genreInput2'), genres)
  })
</script>

<div use:scrollToTop id="top" class="card border mx-auto my-20">
  {#if errors}
    <div class="d-flex">
      <p class="badge badge-danger text-center mt-10 m-auto">
        Whoops! Check form for errors
      </p>
    </div>
  {/if}
  <form class="form" on:submit|preventDefault={onSubmit} autocomplete="off">
    <p>Artist Name</p>
    <input
      class="form-control"
      type="text"
      name="name"
      maxlength="40"
      value={$account.name}
    />
    {#if errors && errors['name']}
      <p class="badge badge-danger">*{errors['name']}</p>
    {/if}
    <p>First Genre</p>
    <div class="autocomplete">
      <input
        id="genreInput1"
        class="form-control"
        type="text"
        name="firstGenre"
        maxlength="40"
        placeholder="black metal"
        value={$account.genres[0]}
      />
    </div>
    {#if errors && errors['firstGenre']}
      <p class="badge badge-danger">*{errors['firstGenre']}</p>
    {/if}
    <p>Second Genre</p>
    <div class="autocomplete">
      <input
        id="genreInput2"
        class="form-control"
        type="text"
        name="secondGenre"
        maxlength="40"
        placeholder="dungeon synth"
        value={$account.genres[1]}
      />
    </div>
    {#if errors && errors['secondGenre']}
      <p class="badge badge-danger">*{errors['secondGenre']}</p>
    {/if}
    <p>Based In</p>
    <input
      class="form-control"
      type="text"
      name="location"
      maxlength="30"
      placeholder="PHILADELPHIA, PA"
      value={$account.location}
    />
    {#if errors && errors['location']}
      <p class="badge badge-danger">*{errors['location']}</p>
    {/if}
    <p>Spotify</p>
    <input
      class="form-control"
      type="text"
      name="spotify"
      maxlength="100"
      placeholder="https://open.spotify.com/..."
      value={$account.links.spotify}
    />
    <button
      class="btn mt-5 glowing"
      type="button"
      on:click={() => openSite('https://open.spotify.com/search')}
    >
      Search Spotify
    </button>
    {#if errors && errors['spotify']}
      <p class="badge badge-danger">*{errors['spotify']}</p>
    {/if}
    <p>Apple Music</p>
    <input
      class="form-control"
      type="text"
      name="apple"
      maxlength="100"
      placeholder="https://music.apple.com/us/artist/..."
      value={$account.links.apple}
    />
    <button
      class="btn mt-5 glowing"
      type="button"
      on:click={() => openSite('https://music.apple.com/search')}
    >
      Search Apple Music
    </button>
    {#if errors && errors['apple']}
      <p class="badge badge-danger">*{errors['apple']}</p>
    {/if}
    <p>Bandcamp</p>
    <input
      class="form-control"
      type="text"
      name="bandcamp"
      maxlength="100"
      placeholder="https://yourband.bandcamp.com/"
      value={$account.links.bandcamp}
    />
    <button
      class="btn mt-5 glowing"
      type="button"
      on:click={() => openSite('https://bandcamp.com/search')}
    >
      Search Bandcamp
    </button>
    {#if errors && errors['bandcamp']}
      <p class="badge badge-danger">*{errors['bandcamp']}</p>
    {/if}
    <p>SoundCloud</p>
    <input
      class="form-control"
      type="text"
      name="soundcloud"
      maxlength="100"
      placeholder="https://soundcloud.com/..."
      value={$account.links.soundcloud}
    />
    <button
      class="btn mt-5 glowing"
      type="button"
      on:click={() => openSite('https://soundcloud.com/search')}
    >
      Search SoundCloud
    </button>
    {#if errors && errors['soundcloud']}
      <p class="badge badge-danger">*{errors['soundcloud']}</p>
    {/if}
    <p>Youtube</p>
    <input
      class="form-control"
      type="text"
      name="youtube"
      maxlength="100"
      placeholder="https://www.youtube.com/..."
      value={$account.links.youtube}
    />
    <button
      class="btn mt-5 glowing"
      type="button"
      on:click={() => openSite('https://youtube.com/search')}
    >
      Search Youtube
    </button>
    {#if errors && errors['youtube']}
      <p class="badge badge-danger">*{errors['youtube']}</p>
    {/if}
    <p>Instagram Username</p>
    <input
      class="form-control"
      type="text"
      name="instagram"
      maxlength="100"
      placeholder="tapedrop"
      value={instagram}
    />
    {#if errors && errors['instagram']}
      <p class="badge badge-danger">*{errors['instagram']}</p>
    {/if}
    <button class="btn mt-5 glowing m-auto mt-20 d-block" type="submit"
      >Submit</button
    >
  </form>
</div>
<div class="d-flex justify-content-center">
  <a
    href="/#"
    class="text-muted"
    on:click|preventDefault={() => deleteAccount($account.uid)}
    >Delete Account</a
  >
</div>

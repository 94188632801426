<script lang="ts">
  import {
    apple,
    bandcamp,
    instagram,
    minus,
    plus,
    share,
    soundcloud,
    spotify,
    youtubePlay
  } from 'svelte-awesome/icons'
  import Icon from 'svelte-awesome'
  import { hasLinks } from '../helpers'
  import { Share } from '@capacitor/share'
  import { Capacitor } from '@capacitor/core'
  import { getAccount, reportAccount } from '../api/account'
  import { alertTimeout, inArray, openApp } from '../helpers'
  import { getProfilePicture, uploadProfilePicture } from '../api/images'
  import { account, alert, authenticated, uploading, page } from '../stores'
  import { addToLibrary, getLibrary, removeFromLibrary } from '../api/artists'

  export let artist: {
    uid?: string
    name: string
    genres?: string[]
    location?: string
    links?: any
    imageURL?: string
  }

  if (artist.imageURL) {
    getProfilePicture(artist.uid, artist.imageURL)
  }

  uploading.set(false)
  let { name, location, links, genres } = artist
  let artistURL = `https://app.tapedrop.com?artist=${artist.uid}`
  let added = !inArray(artist.uid, $account.library)

  function onShare() {
    if (Capacitor.isNativePlatform()) {
      Share.share({
        url: artistURL
      })
    } else {
      navigator.clipboard.writeText(artistURL)
      alert.set('Link Copied')
      alertTimeout()
    }
  }

  async function handleAdd() {
    if (!$authenticated) {
      page.set('login')
      return
    }
    await addToLibrary(artist.uid)
    added = !added
    getAccount($account.uid)
    getLibrary()
  }

  async function handleRemove() {
    await removeFromLibrary(artist.uid)
    added = !added
    getAccount($account.uid)
    getLibrary()
  }
</script>

{#if !hasLinks(artist)}
  <p class="text-center">
    Edit your profile and add music links to show up in Discover. Also, we made
    you a random band name.
  </p>
{/if}
<div class="card border mx-auto my-20 pt-20">
  <!-- svelte-ignore a11y-invalid-attribute -->
  <a href="#" on:click={onShare}>
    <Icon class="share" data={share} />
  </a>
  {#if artist.uid != $account.uid}
    {#if added}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a href="#" on:click={handleAdd}>
        <Icon class="add-or-remove" data={plus} />
      </a>
    {/if}
    {#if !added}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a href="#" on:click={handleRemove}>
        <Icon class="add-or-remove" data={minus} />
      </a>
    {/if}
  {/if}
  <div>
    <div class="row justify-content-center">
      <h1 class="card-title mb-10">
        {name}
      </h1>
    </div>
    {#if artist.imageURL}
      <div class="profile-picture">
        <img id="{artist.uid}-picture" src="" alt="profile" class="d-none" />
      </div>
    {/if}
    {#if $uploading}
      <p class="loading">Uploading...</p>
    {/if}
    {#if artist.uid == $account.uid && $page == 'account'}
      <div class="d-flex justify-content-center">
        <div class="custom-file">
          <label class="btn glowing" for="image-input"
            >New Profile Picture</label
          >
          <input
            id="image-input"
            type="file"
            accept="image/png, image/jpeg"
            on:change={async (e) =>
              // @ts-ignore
              await uploadProfilePicture(artist.uid, e.target.files[0])}
          />
        </div>
      </div>
    {/if}
    {#if genres[0]}
      <div class="text-center mt-20 mb-10">
        {#if genres[1]}
          {genres[0] + ', ' + genres[1]}
        {:else}
          {genres[0]}
        {/if}
      </div>
    {/if}
    <div class="row justify-content-center social">
      {#if links.spotify}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click={() => openApp(links.spotify)}>
          <Icon class="spotify" data={spotify} />
        </a>
      {/if}
      {#if links.apple}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click={() => openApp(links.apple)}>
          <Icon class="apple" data={apple} />
        </a>
      {/if}
      {#if links.bandcamp}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click={() => openApp(links.bandcamp)}>
          <Icon class="bandcamp" data={bandcamp} />
        </a>
      {/if}
      {#if links.soundcloud}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click={() => openApp(links.soundcloud)}>
          <Icon class="soundcloud" data={soundcloud} />
        </a>
      {/if}
      {#if links.instagram}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click={() => openApp(links.instagram)}>
          <Icon class="instagram" data={instagram} />
        </a>
      {/if}
      {#if links.youtube}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click={() => openApp(links.youtube)}>
          <Icon class="youtube" data={youtubePlay} />
        </a>
      {/if}
    </div>
    {#if location}
      <p class="text-center">{location}</p>
    {/if}
    {#if artist.uid != $account.uid && $page != 'artist'}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a href="#" on:click={() => reportAccount(artist.uid)}>
        <p class="text-center text-muted mt-20 mb-0">report profile</p>
      </a>
    {/if}
  </div>
</div>

<script lang="ts">
  import { onMount } from 'svelte'
  import { alert } from '../stores'
  import { getArtist } from '../api/artists'

  import ArtistCard from '../components/ArtistCard.svelte'

  export let uid: string
  let promise = getArtist(uid)

  onMount(() => {
    document
      .getElementsByClassName('page-wrapper')[0]
      .classList.remove('with-navbar', 'with-navbar-fixed-bottom')
  })
</script>

{#if $alert}
  <div class="alert alert-artist" role="alert">
    <p class="text-center">{$alert}</p>
  </div>
{/if}
{#await promise then artist}
  <ArtistCard {artist} />
  <a href="/"><p class="text-center text-muted">Artist Card by Tapedrop</p></a>
{/await}

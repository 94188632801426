import { auth } from '../firebase';
import { get } from 'svelte/store';
import { getAccount } from './account';
import { Capacitor } from '@capacitor/core';
import { alertTimeout, nullAccount, params } from '../helpers';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { account, alert, authenticated, page } from '../stores';
import { FirebaseAuthentication } from '@robingenz/capacitor-firebase-authentication';
import { GoogleAuthProvider, OAuthProvider, signInWithCredential, signInWithPopup } from 'firebase/auth';
export function logout() {
    signOut(auth);
}
export async function appleLogin() {
    var _a, _b;
    if (Capacitor.isNativePlatform()) {
        // 1. Create credentials on the native layer
        const result = await FirebaseAuthentication.signInWithApple();
        // 2. Sign in on the web layer using the id token and nonce
        const credential = OAuthProvider.credentialFromJSON({
            providerId: 'apple.com',
            signInMethod: 'apple.com',
            idToken: (_a = result.credential) === null || _a === void 0 ? void 0 : _a.idToken,
            nonce: (_b = result.credential) === null || _b === void 0 ? void 0 : _b.nonce,
            pendingToken: null
        });
        await signInWithCredential(auth, credential);
    }
    else {
        const provider = new OAuthProvider('apple.com');
        await signInWithPopup(auth, provider);
    }
}
export async function googleLogin() {
    var _a;
    if (Capacitor.isNativePlatform()) {
        // 1. Create credentials on the native layer
        const result = await FirebaseAuthentication.signInWithGoogle();
        // 2. Sign in on the web layer using the id token
        const credential = GoogleAuthProvider.credential((_a = result.credential) === null || _a === void 0 ? void 0 : _a.idToken);
        await signInWithCredential(auth, credential);
    }
    else {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
    }
}
onAuthStateChanged(auth, async (user) => {
    if (user) {
        await getAccount(user.uid);
        authenticated.set(true);
        if (!params.has('artist')) {
            alert.set('Welcome!');
        }
        alertTimeout();
    }
    else {
        if (get(page) == 'edit') {
            alert.set('Account deleted');
        }
        alertTimeout();
        account.set(nullAccount);
        authenticated.set(false);
    }
    if (params.has('artist')) {
        page.set('artist');
    }
    else if (get(page) == 'login' && user) {
        page.set('account');
    }
    else {
        page.set('discover');
    }
});

import { db } from '../firebase';
import { get } from 'svelte/store';
import { account, alert } from '../stores';
import { alertTimeout, docToJSON, hasLinks } from '../helpers';
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
export async function addToLibrary(uid) {
    const ref = doc(db, 'accounts', get(account).uid);
    await updateDoc(ref, {
        library: arrayUnion(uid)
    });
    alert.set('Added to Library');
    alertTimeout();
}
export async function getArtist(uid) {
    const ref = doc(db, 'accounts', uid);
    const snapshot = await getDoc(ref);
    return docToJSON(snapshot.data());
}
export async function getLibrary() {
    const library = [];
    for (const uid of get(account).library) {
        const ref = doc(db, 'accounts', uid);
        const snapshot = await getDoc(ref);
        if (snapshot.exists()) {
            let artist = docToJSON(snapshot.data());
            if (hasLinks(artist)) {
                library.push(docToJSON(snapshot.data()));
            }
        }
    }
    return library.reverse();
}
export async function removeFromLibrary(uid) {
    const ref = doc(db, 'accounts', get(account).uid);
    await updateDoc(ref, {
        library: arrayRemove(uid)
    });
    alert.set('Removed from Library');
    alertTimeout();
}

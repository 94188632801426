import bandname from 'bandname';
import { auth, db } from '../firebase';
import { signOut } from '@firebase/auth';
import { alertTimeout, docToJSON, formMap, hasLinks } from '../helpers';
import { account, alert, page } from '../stores';
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, Timestamp } from 'firebase/firestore';
export async function createAccount(uid) {
    try {
        const docRef = doc(db, 'accounts', uid);
        const accountData = newAccount(uid);
        await setDoc(docRef, accountData);
        account.set(accountData);
        alert.set('Account Created');
        alertTimeout();
    }
    catch (e) {
        console.error(e);
    }
}
export async function deleteAccount(uid) {
    let confirmed = confirm('Your account will be permanently deleted.');
    if (confirmed) {
        try {
            await deleteDoc(doc(db, 'accounts', uid));
            await syncGenres();
            await signOut(auth);
            alert.set('Account Removed');
            alertTimeout();
        }
        catch (e) {
            console.error(e);
        }
    }
}
export async function getAccount(uid) {
    const docRef = doc(db, 'accounts', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const accountData = docToJSON(docSnap.data());
        account.set(accountData);
    }
    else {
        await createAccount(uid);
    }
}
export async function reportAccount(uid) {
    try {
        const docRef = doc(db, 'reports', uid);
        await setDoc(docRef, { account: uid });
        alert.set('Account Reported');
        alertTimeout();
    }
    catch (e) {
        console.error(e);
    }
}
export async function updateAccount(uid, data) {
    const docRef = doc(db, 'accounts', uid);
    await setDoc(docRef, formMap(data), { merge: true });
    await syncGenres();
    page.set('account');
    alert.set('Account Updated');
    alertTimeout();
}
function newAccount(uid) {
    return {
        uid: uid,
        name: bandname(),
        genres: ['', ''],
        location: '',
        links: {
            spotify: '',
            apple: '',
            bandcamp: '',
            soundcloud: '',
            instagram: '',
            youtube: ''
        },
        library: [],
        imageURL: '',
        updated_at: Timestamp.now()
    };
}
async function syncGenres() {
    let genres = [];
    const accountsRef = collection(db, 'accounts');
    const snapshot = await getDocs(accountsRef);
    for (const doc of snapshot.docs) {
        let artist = doc.data();
        if (artist.genres && hasLinks(artist)) {
            for (const genre of artist.genres) {
                if (genre != '') {
                    genres.push(genre);
                }
            }
        }
    }
    let uniqueGenres = [...new Set(genres)];
    const genresRef = doc(db, 'genres', 'genres');
    await setDoc(genresRef, { genres: uniqueGenres });
}

<script lang="ts">
  import 'halfmoon/css/halfmoon-variables.min.css'
  import './custom.css'

  import Icon from 'svelte-awesome'
  import { authenticated, alert, page } from './stores'
  import { halfmoonInit, params, openSite } from './helpers'
  import { mapMarker, music, newspaperO, smileO } from 'svelte-awesome/icons'

  import Edit from './pages/Edit.svelte'
  import Login from './pages/Login.svelte'
  import Artist from './pages/Artist.svelte'
  import Library from './pages/Library.svelte'
  import Account from './pages/Account.svelte'
  import Discover from './pages/Discover.svelte'

  halfmoonInit()

  if (params.has('artist')) {
    page.set('artist')
  }
</script>

<main>
  {#if authenticated == null}
    <p class="loading">Loading...</p>
  {/if}
  {#if $authenticated != null}
    <div class="page-wrapper with-navbar with-navbar-fixed-bottom">
      {#if $alert && $page != 'artist'}
        <div class="alert" role="alert">
          <p class="text-center">{$alert}</p>
        </div>
      {/if}
      <nav class="navbar">
        <div class="nav-items">
          <img class="logo" src="/images/tapedrop-logo.png" alt="logo" />
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class="blog"
            href="#"
            on:click={() => openSite('https://tapedrop.com')}
          >
            <Icon data={newspaperO} scale={2} />
          </a>
        </div>
      </nav>
      <div class="content-wrapper p-10">
        {#if $page == 'account' && $authenticated}
          <Account />
        {/if}
        {#if $page == 'edit' && $authenticated}
          <Edit />
        {/if}
        {#if $page == 'library' && $authenticated}
          <Library />
        {/if}
        {#if $page == 'discover'}
          <Discover />
        {/if}
        {#if $page == 'artist'}
          <Artist uid={params.get('artist')} />
        {/if}
        {#if $page == 'login' && !$authenticated}
          <Login />
        {/if}
      </div>
      <nav class="navbar navbar-fixed-bottom">
        {#if $authenticated}
          <div class="nav-items">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a href="#" class="account" on:click={() => page.set('account')}
              ><Icon data={smileO} class="m-auto" />
              <p class="m-0">Account</p></a
            >

            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
              href="#"
              class="discover selectedPage"
              on:click={() => page.set('discover')}
              ><Icon data={mapMarker} class="m-auto" />
              <p class="m-0">Discover</p></a
            >

            <!-- svelte-ignore a11y-invalid-attribute -->
            <a href="#" class="library" on:click={() => page.set('library')}
              ><Icon data={music} class="m-auto" />
              <p class="m-0">Library</p></a
            >
          </div>
        {/if}
        {#if !$authenticated && $page != 'login'}
          <div class="login-btn">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a class="login" href="#" on:click={() => page.set('login')}>
              <p class="login-text">Sign In</p></a
            >
          </div>
        {/if}
      </nav>
    </div>
  {/if}
</main>

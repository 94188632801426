<script lang="ts">
  import { onMount } from 'svelte'
  import { logout } from '../api/auth'
  import { activePage } from '../helpers'
  import { page, account } from '../stores'
  import { getAccount } from '../api/account'

  import ArtistCard from '../components/ArtistCard.svelte'

  let promise = getAccount($account.uid)

  onMount(() => {
    activePage('account')
  })
</script>

<h1 class="page-title text-center">Account</h1>
{#await promise}
  <p class="loading">Loading...</p>
{:then}
  <ArtistCard artist={$account} />
{/await}
<div class="d-flex justify-content-center">
  <button class="btn glowing account-btn mx-5 " on:click={logout}
    >Log Out</button
  >
  <button
    class="btn glowing account-btn mx-5"
    on:click={() => {
      page.set('edit')
    }}>Edit Profile</button
  >
</div>
